import React, { useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile, faChevronDown, faChevronUp, faSpinner, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import * as Collapsible from '@radix-ui/react-collapsible';

// Import player images
import bedardImage from './bedard.png';
import matthewsImage from './matthews.png';
import ovechkinImage from './ovechkin.png';
import gretzkyImage from './gretzky.png';

// Import new components and utilities
import PlayerTable from './components/PlayerTable';
import { api, calculateMaxMinValues, processPlayers, playerColors, graphPlayers } from './utils/helpers';
import { graphData } from './data/graphData';

function App() {
  const [gretzky, setGretzky] = useState({
    id: '8447400',
    name: 'Wayne Gretzky',
    image: gretzkyImage,
    goals: 894,
    gamesPlayed: 1487,
    link: 'https://www.nhl.com/player/wayne-gretzky-8447400'
  });

  const [players, setPlayers] = useState([
    { id: '8471214', name: 'Alexander Ovechkin', apiName: 'Alex Ovechkin', image: ovechkinImage, goals: 0, gamesPlayed: 0, link: 'https://www.nhl.com/capitals/player/alex-ovechkin-8471214' },
    { id: '8479318', name: 'Auston Matthews', apiName: 'Auston Matthews', image: matthewsImage, goals: 0, gamesPlayed: 0, link: 'https://www.nhl.com/mapleleafs/player/auston-matthews-8479318' },
    { id: '8484144', name: 'Connor Bedard', apiName: 'Connor Bedard', image: bedardImage, goals: 0, gamesPlayed: 0, link: 'https://www.nhl.com/fi/player/connor-bedard-8484144' }
  ]);

  const [bubblingUnderPlayers, setBubblingUnderPlayers] = useState([
    { id: '8480027', name: 'Jason Robertson', apiName: 'Jason Robertson', goals: 0, gamesPlayed: 0, link: 'https://www.nhl.com/player/jason-robertson-8480027' },
    { id: '8477934', name: 'Connor McDavid', apiName: 'Connor McDavid', goals: 0, gamesPlayed: 0, link: 'https://www.nhl.com/player/connor-mcdavid-8477934' },
    { id: '8477956', name: 'David Pastrnak', apiName: 'David Pastrnak', goals: 0, gamesPlayed: 0, link: 'https://www.nhl.com/player/david-pastrnak-8477956' },
    { id: '8474564', name: 'Steven Stamkos', apiName: 'Steven Stamkos', goals: 0, gamesPlayed: 0, link: 'https://www.nhl.com/player/steven-stamkos-8474564' },
    { id: '8477493', name: 'Leon Draisaitl', apiName: 'Leon Draisaitl', goals: 0, gamesPlayed: 0, link: 'https://www.nhl.com/player/leon-draisaitl-8477493' },
    { id: '8479339', name: 'Patrik Laine', apiName: 'Patrik Laine', goals: 0, gamesPlayed: 0, link: 'https://www.nhl.com/player/patrik-laine-8479339' },
    { id: '8478420', name: 'Mikko Rantanen', apiName: 'Mikko Rantanen', goals: 0, gamesPlayed: 0, link: 'https://www.nhl.com/player/mikko-rantanen-8478420' }
  ]);

  const [sortConfig, setSortConfig] = useState({ key: 'gamesNeeded', direction: 'ascending' });
  const [isBubblingUnderVisible, setIsBubblingUnderVisible] = useState(false);
  const [isDetailedStatsVisible, setIsDetailedStatsVisible] = useState(false);
  const [maxGoals, setMaxGoals] = useState(0);
  const [nextGoal, setNextGoal] = useState(896);
  const [error, setError] = useState(null);

  const targetGoals = 895;

  const updateMaxGoals = (playerList) => {
    const newMaxGoals = Math.max(...playerList.map(p => p.goals));
    setMaxGoals(newMaxGoals);
    if (newMaxGoals >= targetGoals) {
      setNextGoal(newMaxGoals + 1);
    }
  };

  const fetch2024Data = async (player) => {
    try {
      const response = await api.get('/api/season-stats');
      const playerData = response.data.data.find(p => p.skaterFullName === player.apiName);
      if (playerData) {
        return {
          goals2024: playerData.goals || 0,
          games2024: playerData.gamesPlayed || 0
        };
      }
      return { goals2024: 0, games2024: 0 };
    } catch (error) {
      console.error('Error fetching 2024-2025 data:', error);
      return { goals2024: 0, games2024: 0 };
    }
  };

  useEffect(() => {
    const fetchPlayerData = async (player) => {
      try {
        const [currentData, data2024] = await Promise.all([
          api.get(`/api/player/${player.id}`),
          fetch2024Data(player)
        ]);
        return {
          ...player,
          goals: currentData.data.goals,
          gamesPlayed: currentData.data.gamesPlayed,
          ...data2024
        };
      } catch (error) {
        console.error('Error fetching player data:', error);
        setError('Failed to fetch player data. Please try again later.');
        return player;
      }
    };

    Promise.all(players.map(fetchPlayerData)).then(updatedPlayers => {
      setPlayers(updatedPlayers);
      updateMaxGoals(updatedPlayers);
    });
    Promise.all(bubblingUnderPlayers.map(fetchPlayerData)).then(updatedBubblingUnderPlayers => {
      setBubblingUnderPlayers(updatedBubblingUnderPlayers);
      updateMaxGoals([...updatedBubblingUnderPlayers, ...players]);
    });
  }, []);

  const sortData = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedPlayers = useMemo(() => {
    let sortablePlayers = processPlayers(players, targetGoals);
    sortablePlayers.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    return sortablePlayers;
  }, [players, sortConfig, targetGoals]);

  const sortedBubblingUnderPlayers = useMemo(() => {
    let sortablePlayers = processPlayers(bubblingUnderPlayers, targetGoals);
    sortablePlayers.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    return sortablePlayers;
  }, [bubblingUnderPlayers, sortConfig, targetGoals]);

  const mainPlayerMaxMinValues = useMemo(() => calculateMaxMinValues(sortedPlayers), [sortedPlayers]);
  const bubblingUnderMaxMinValues = useMemo(() => calculateMaxMinValues(sortedBubblingUnderPlayers), [sortedBubblingUnderPlayers]);

  const ratioData = useMemo(() => {
    return graphData.map(entry => {
      const ratioEntry = { game: entry.game };
      graphPlayers.forEach(player => {
        if (entry[player] !== null) {
          ratioEntry[player] = parseFloat(entry[player] / entry.game).toFixed(2);
        } else {
          ratioEntry[player] = null;
        }
      });
      return ratioEntry;
    });
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-violet-600 via-gray-800 to-blue-900 py-4 px-2">
<div className="max-w-md md:max-w-2xl lg:max-w-2xl xl:max-w-6xl mx-auto">
  <h1 style={{
    fontSize: '25px',
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: '1px',
    fontFamily: 'Helvetica, monospace',
    backgroundImage: 'linear-gradient(to right, #09f1b8, #00a2ff, #ff00d2, #fed90f)',
    WebkitTextStroke: 'calc(1em / 6) transparent',  // Ensimmäinen vaihe: poista taustakuva leikkauksesta
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'black',  // Sisäosa valkoiseksi
    WebkitTextStrokeColor: 'transparent', // Varmistaa, että gradient ei vaikuta sisäosaan
    WebkitTextStrokeWidth: 'calc(1em / 5)',  // Tekstin ääriviivojen leveys
    padding: 'calc(1em / 32)'
  }}>
    ROAD TO 895
  </h1>
        <p className="text-sm text-white text-center mb-4">...Their journey towards the 'unbeatable' record...</p>
        
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-2 py-1 rounded relative mb-4 text-sm" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        
        <div className="bg-white rounded-lg shadow-md mb-4">
          <PlayerTable 
            players={processPlayers([gretzky], targetGoals)} 
            showImage={true}
            maxMinValues={calculateMaxMinValues([gretzky])}
            isGretzky={true}
            sortConfig={sortConfig}
            sortData={sortData}
            targetGoals={targetGoals}
          />
        </div>
        
        <div className="bg-white rounded-lg shadow-md mb-4">
          <PlayerTable 
            players={sortedPlayers} 
            showImage={true}
            maxMinValues={mainPlayerMaxMinValues}
            sortConfig={sortConfig}
            sortData={sortData}
            targetGoals={targetGoals}
          />
        </div>
        
        <button
          onClick={() => setIsBubblingUnderVisible(!isBubblingUnderVisible)}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-200 flex items-center justify-center text-sm mb-4"
        >
          <FontAwesomeIcon icon={faSpinner} className="mr-2" /> Bubbling under
          <FontAwesomeIcon icon={isBubblingUnderVisible ? faChevronUp : faChevronDown} className="ml-2" />
        </button>
        
        {isBubblingUnderVisible && (
          <div className="bg-white rounded-lg shadow-md mb-4">
            <PlayerTable
              players={sortedBubblingUnderPlayers}
              showImage={false}
              maxMinValues={bubblingUnderMaxMinValues}
              sortConfig={sortConfig}
              sortData={sortData}
              targetGoals={targetGoals}
            />
          </div>
        )}

        <Collapsible.Root className="w-full mb-4" open={isDetailedStatsVisible} onOpenChange={setIsDetailedStatsVisible}>
          <Collapsible.Trigger className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-200 flex items-center justify-center text-sm">
            <FontAwesomeIcon icon={faChartBar} className="mr-2" /> Detailed stats
            <FontAwesomeIcon icon={isDetailedStatsVisible ? faChevronUp : faChevronDown} className="ml-2" />
          </Collapsible.Trigger>
          <Collapsible.Content className="mt-4 bg-white rounded-lg shadow-md p-4">
            <div className="w-full h-[300px] mb-4">
              <h3 className="text-sm font-semibold mb-2">Goals</h3>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={graphData}
                  margin={{ top: 5, right: 5, left: 0, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="game" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  {graphPlayers.map(player => (
                    <Line
                      key={player}
                      type="monotone"
                      dataKey={player}
                      stroke={playerColors[player]}
                      name={player.charAt(0).toUpperCase() + player.slice(1)}
                      connectNulls
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div className="w-full h-[300px]">
              <h3 className="text-sm font-semibold mb-2">Goals/game</h3>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={ratioData}
                  margin={{ top: 5, right: 5, left: 0, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="game" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  {graphPlayers.map(player => (
                    <Line
                      key={player}
                      type="monotone"
                      dataKey={player}
                      stroke={playerColors[player]}
                      name={player.charAt(0).toUpperCase() + player.slice(1)}
                      connectNulls
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            </div>  <div><br></br></div>
          </Collapsible.Content>
        
        </Collapsible.Root>
      </div>
      <div className="flex justify-center mt-4 mb-4">
        <a href="https://www.buymeacoffee.com/visakytomay" target="_blank" rel="noopener noreferrer" className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-md hover:shadow-lg flex items-center transition-all duration-200 text-sm">
          <img src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" alt="Buy me a coffee" className="w-5 h-5 mr-2" />
          <span>Buy me a coffee!<FontAwesomeIcon icon={faSmile} className="ml-2 text-white" /></span>
        </a>
      </div>
    </div>
  );
}

export default App;
