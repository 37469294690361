export const graphData = [
  { game: 100, ovechkin: 52, gretzky: 61, matthews: 55, stamkos: 51, pastrnak: 44, McDavid: 45, rantanen: 33 },
  { game: 200, ovechkin: 109, gretzky: 153, matthews: 112, stamkos: 96, pastrnak: 97, McDavid: 105, rantanen: 84 },
  { game: 300, ovechkin: 167, gretzky: 250, matthews: 171, stamkos: 156, pastrnak: 159, McDavid: 170, rantanen: 134 },
  { game: 400, ovechkin: 233, gretzky: 340, matthews: 237, stamkos: 208, pastrnak: 215, McDavid: 240, rantanen: 185 },
  { game: 500, ovechkin: 301, gretzky: 424, matthews: 299, stamkos: 268, pastrnak: 272, McDavid: 303, rantanen: 236 },
  { game: 600, ovechkin: 371, gretzky: 512, matthews: 357, stamkos: 326, pastrnak: 328, McDavid: 372, rantanen: 287 },
  { game: 700, ovechkin: 437, gretzky: 599, matthews: null, stamkos: 383, pastrnak: null, McDavid: 433, rantanen: null },
  { game: 800, ovechkin: 503, gretzky: 684, matthews: null, stamkos: 439, pastrnak: null, McDavid: null, rantanen: null },
  { game: 900, ovechkin: 569, gretzky: 765, matthews: null, stamkos: 495, pastrnak: null, McDavid: null, rantanen: null },
  { game: 1000, ovechkin: 637, gretzky: 837, matthews: null, stamkos: 550, pastrnak: null, McDavid: null, rantanen: null },
  { game: 1100, ovechkin: 706, gretzky: 877, matthews: null, stamkos: null, pastrnak: null, McDavid: null, rantanen: null },
  { game: 1200, ovechkin: 780, gretzky: 894, matthews: null, stamkos: null, pastrnak: null, McDavid: null, rantanen: null },
  { game: 1300, ovechkin: 822, gretzky: 894, matthews: null, stamkos: null, pastrnak: null, McDavid: null, rantanen: null },
];